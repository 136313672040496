import { graphql } from "gatsby";
import React, { useState, useRef } from "react";
import BlockContent from "@sanity/block-content-to-react";
import Section from "../components/Section";
import SEO from "../components/SEO";
import Img from "gatsby-image";
import styled from "styled-components";
import { below } from "../styles/Breakpoints";

const sectionWidth = "720px";

const TeamPage = styled.div`
  padding-bottom: 100px;
  ${below.mobileL`
    padding-bottom: 20px;
  `}
`;

const StyledMemberBio = styled.div`
  max-width: ${sectionWidth};
  width: 100%;
  margin-bottom: 2rem;

  .member-name {
    margin-bottom: 0;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    max-width: 225px;
    max-height: 225px;
    border: 2px solid var(--primaryHover);
    float: right;
    margin-bottom: 0.25rem;
    margin-left: 1rem;
    display: block;
  }

  &:nth-of-type(odd) {
    .gatsby-image-wrapper {
      float: left;
      margin-right: 1rem;
      margin-left: 0;
    }
  }

  .bio-image-container {
    line-height: 0;
    p {
      font-size: 0.9rem;
      font-size: clamp(0.8rem, 0.5rem + 1vw, 0.9rem);
      @supports not (font-size: clamp(0.8rem, 0.5rem + 1vw, 0.9rem)) {
        ${below.mobileL`
          font-size: .8rem;
        `}
      }
    }
  }

  ${below.tablet`
    .member-name, .member-title {
      text-align: center;
    }

    .bio-image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      & .gatsby-image-wrapper {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  `}
`;

export default function Team({ data }) {
  const team = data.team.nodes;

  return (
    <TeamPage>
      <Section width={sectionWidth}>
        <h1>Team</h1>
        <div className="header-underline"></div>
        {team.map(member => (
          <StyledMemberBio key={member.id}>
            <p className="bold member-name">{member.name}</p>
            <p className="italic member-title">{member.title}</p>
            <div className="bio-image-container">
              {member.image && (
                <Img
                  fluid={member.image.asset.fluid}
                  loading="eager"
                  alt={`${member.name} photo`}
                />
              )}
              <BlockContent
                blocks={member._rawBio}
                projectId="hq5iqyv5"
                dataset="production"
                imageOptions={{ w: 600, fit: "max" }}
              />
            </div>
          </StyledMemberBio>
        ))}
      </Section>
    </TeamPage>
  );
}

export const teamQuery = graphql`
  query teamQuery {
    team: allSanityAuthor(sort: { fields: [order], order: ASC }) {
      nodes {
        name
        title
        order
        id
        _rawBio
        image {
          asset {
            fluid(maxWidth: 300, maxHeight: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
